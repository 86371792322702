import { render, staticRenderFns } from "./HeaderPrimary.vue?vue&type=template&id=b80a4c48&scoped=true"
import script from "./HeaderPrimary.js?vue&type=script&lang=js&external"
export * from "./HeaderPrimary.js?vue&type=script&lang=js&external"
import style0 from "./HeaderPrimary.scss?vue&type=style&index=0&id=b80a4c48&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80a4c48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcSearchBar: require('/drone/src/components/Molecules/SearchBar/SearchBar.vue').default,HcLanguageSwitch: require('/drone/src/components/Molecules/LanguageSwitch/LanguageSwitch.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
