import { mapActions, mapGetters } from 'vuex'
import cas from '~/mixins/cas'
import header from '~/mixins/header.js'
import url from '~/mixins/url-structure'
import user from '~/mixins/user'
import { convertNameAvatar } from '~/utils/sharedHelpers'

export default {
  mixins: [user, header, cas, url],
  computed: {
    ...mapGetters('casUser', ['userCas', 'userToken', 'userProfile']),

    avatar: function () {
      return convertNameAvatar(this.userProfile?.name)
    },
    renderButtonLogin() {
      return !this.userToken
    },
  },
  methods: {
    ...mapActions('casUser', ['resetUserCas', 'setUserCas']),
    ...mapActions('summaryScreeningFeedbackModal', ['openSummaryScreeningFeedbackModal']),

    async loginCas() {
      if (await this.openSummaryScreeningFeedbackModal()) return
      this.isLoading = !this.isLoading
      this.signinRedirect()
    },

    isTokenValid() {
      const expirationTimestamp = this.userCas?.expires_at
      const currentTimestamp = Math.round(new Date().getTime() * 1000)
      const isUserWithToken = this.userToken
      if (isUserWithToken && Boolean(expirationTimestamp && expirationTimestamp * 1000 > currentTimestamp)) {
        return this.resetUserCas()
      }
    },
  },
  data() {
    return {
      isLoading: false,
      locale: this.$i18n.locale,
      homePath: `/${this.$i18n.locale}`,
    }
  },
  watch: {
    userToken: {
      immediate: true,
      handler(token) {
        if (token) {
          this.isTokenValid()
        }
      },
    },
  },
}
