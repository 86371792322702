import socialIcons from './socialIcons.json'

export default {
  data() {
    return {
      socialIcons: socialIcons,
    }
  },
  methods: {
    toggleLinks(e) {
      const clickedSocialItem = e.currentTarget
      const socialIcons = this.$refs.socialIcon
      const socialIconsWithoutClickedSocialIcon = [...socialIcons].filter(
        socialIcon => socialIcon !== clickedSocialItem
      )
      socialIconsWithoutClickedSocialIcon.forEach(socialIcon => {
        socialIcon.classList.remove('social-icon__links--open')
      })
      const clickedSocialItemLinks = clickedSocialItem.querySelector('.social-icons__links')
      if (clickedSocialItemLinks) {
        clickedSocialItem.classList.toggle('social-icon__links--open')
      }
      this.clearTextSelection()
    },
    clearTextSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        let elements = document.getElementsByClassName('social-icons__item')
        Object.values(elements).forEach(element => {
          element.classList.remove('social-icon__links--open')
        })
      }
    },
  },
}
