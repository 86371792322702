export default {
  data() {
    return {
      year: null,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  created() {
    this.year = new Date().getFullYear()
  },
}
