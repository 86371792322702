import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=32262f1a&scoped=true"
import script from "./Footer.js?vue&type=script&lang=js&external"
export * from "./Footer.js?vue&type=script&lang=js&external"
import style0 from "./Footer.scss?vue&type=style&index=0&id=32262f1a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32262f1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcSocialIcons: require('/drone/src/components/Molecules/SocialIcons/SocialIcons.vue').default,HcLanguageSwitch: require('/drone/src/components/Molecules/LanguageSwitch/LanguageSwitch.vue').default,HcLink: require('/drone/src/components/Molecules/Link/Link.vue').default})
