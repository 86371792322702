import { computed, provide, ref } from '@nuxtjs/composition-api'
import { MY_REQUESTS_STEP_KEY, STEPS } from './constants'


export default {
  __name: 'HeaderSidebarMenuMyRequests',
  setup(__props) {

const step = ref(STEPS.MY_RESQUESTS_FAKE_LINK)
const component = computed(() => `HcHeaderSidebarMenuMyRequestsStep${step.value}`)

provide(MY_REQUESTS_STEP_KEY, step)

return { __sfc: true,step, component }
}

}